html,body{
  margin: 0;
  padding: 0;
  width: 100%;
}
.App{
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px;
}
img{
  padding: 10px;
  width: 50px;
  height: 50px;
  border: solid 1px #333;
  border-radius: 20px;
  background-color: white;
  cursor: pointer;
}
.position-relative{
  position: relative;
}
.chat-content-main{
  border: solid 1px #c2c2c2;
  bottom: 5px;
  right: 0;
  position: absolute;
  width: 350px;
  height: 520px;
  background-color: aquamarine;
}

.chat-content {
  display: flex;
  flex-direction: column;
  height: 100%; /* Ajustar la altura del contenedor principal al 100% del contenedor padre */
}

.header {
  padding: 20px;
  background-color: #f0f0f0; /* Color de fondo de la cabecera */
}

.body {
  padding: 5px;
  display: flex;
  flex-direction: column-reverse; /* Cambia el orden de los elementos a descendente */
  height: 390px;
  overflow-y: auto;
}

.footer {
  height: 60px;
  background-color: #f0f0f0; /* Color de fondo del pie de página */
}

/* Estilos adicionales para el textarea del chat */
.footer textarea {
  width: 100%;
  height: 100%; /* Ajustar la altura del textarea al 100% del footer */
  border: none;
  resize: none; /* Deshabilitar la redimension del textarea */
  padding: 10px; /* Ajustar el relleno interior del textarea */
  box-sizing: border-box; /* Incluir el relleno y el borde en la medida total del textarea */
}

.yo {
  align-self: flex-end;
  background-color: #DCF8C6; /* Color de fondo para los mensajes "yo" */
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 8px;
}

.el {
  align-self: flex-start;
  background-color: #EAEAEA; /* Color de fondo para los mensajes "el" */
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 8px;
}
